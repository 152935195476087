//----


#Trailers li:first-child {
    max-width: calc(2 * var(--column));
}


#Library {
    .toc {
        position: absolute;
        top: 0px;
        text-align: right;
        max-width: calc(var(--column) - 100% - 2rem);
        right: calc(0px - var(--column) + 100%);
        overflow:visible;

        li {background:white;}
        li.focused {
            background:blue;
            a:link[href] {color:white;}
            a:visited[href] {color:rgba(255,255,255,.8)}
        }
    }
}

#Video-Channel, #Trailers {
    background-color: #130036;
    .trailer-thumb {
        display:inline-block;
        max-width:calc(50% - 0.5px);
        min-width: var(--column);
    }
    .deferredVimeoVideo {
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100vw;
            height: calc(9 / 16 * 100vw);
            z-index: 200;
            background: rgba(0,0,0,0.7);
            cursor:zoom-in;
            }
        summary {list-style: none;}
        .placeholder {height:0; overflow:hidden;}
    }
    details.deferredVimeoVideo[open] {
        summary {
            cursor:zoom-out;
            img {opacity:0;}
            width: 200vw;
            height: 200vh;
            z-index: 100;
            cursor: zoom-out;
            background: #000000b3;
            position: absolute;
            top: -50vh;
            left: -50vw;
        }
        iframe {
            animation: fadeIn 1s ease-out;
        }
        .placeholder {height:auto;}
    }
}


#Archive {
    >.body {
        height: var(--here-height);
        overflow: visible;
    }

    body {
        border: none;
        background: transparent;
    }

    #root {
        border: none;
        background: transparent;

        >div {
            border: none;
            background: transparent;
        }
    }
}

.role+a.person {
    margin-left: 0.5rem;
    transition: all 0.2s;

    &:hover {
        letter-spacing: 0.5px;
    }
}

a.medium {
    &:hover {
        letter-spacing: 0.5px;
    }
}

ul.stretch-horizontally {
    display: flex;
    width: 100%;

    >li {
        flex-grow: 1;
    }
}

ul.video-carousel {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        display: inline-block;
        width: 33.3%;
        padding: 0;
        margin: 0;
    }

    &.dense {
        display: flex;
        max-width: 100%;
        overflow-x: scroll;
        .seeLab, .seeNothing {display:block; padding:0.5rem; line-height:1rem}
        summary {cursor:zoom-in; min-width:4.5rem;}
    }
}

.traces-container {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

iframe.traces {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    border: none;
}

.motto {
    position: absolute;
    right: 0.25rem;
    font-weight: bold;
    font-family: space_monoregular;
}

.artist {
    .weblink {
        display: block;
        margin-left: -7rem;
        border-bottom: 0.5px solid #8c8cfc;
        padding-left: 7rem;
        margin-top: 1.5rem;
        width: fit-content;
    }

    fieldset {
        animation: scroll 7s linear infinite;
        animation: scroll 7s linear infinite;
        background-image: linear-gradient(transparent, blue, transparent);
        background-image: linear-gradient(transparent, blue, transparent);
        background-size: 400px 1000px;
        background-size: 400px 1000px;
        margin: 1.5rem -1rem;
        margin: 1.5rem -1rem;
        padding: 0.25rem 1rem;
        padding: 0.25rem 1rem;
        padding-bottom: 1rem;
        padding-bottom: 1rem;
        border: none;
        border: none;

        .weblink {
            margin-top: 0;
        }

        a {
            &:hover {
                background: blue;
                background: blue;
            }
        }
    }

    a.internal[href] {
        &:hover {
            background: blue;
        }

        animation: scroll 7s linear infinite;
        background-image: linear-gradient(transparent, blue, transparent);
        background-size: 400px 1000px;
        display: block;
    }
}

.F>img.artist,
.A img.artist {
    mix-blend-mode: overlay;
    position: relative;
    left: 1rem;
    width: calc(100% - var(--bleed));
}


.generic.lab {
    padding: 0 1.125rem;
}


#Home {
    // position: fixed;

    .body {
        // top: 0;
        // position: fixed;
    }
}

ul.credits {
    padding-left: 1.25rem;
}

.role+.who {
    margin-left: .25rem;
}