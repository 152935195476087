#Trailers li:first-child {
  max-width: calc(2 * var(--column) );
}

#Library .toc {
  text-align: right;
  max-width: calc(var(--column)  - 100% - 2rem);
  top: 0;
  right: calc(0px - var(--column)  + 100%);
  position: absolute;
  overflow: visible;
}

#Library .toc li {
  background: #fff;
}

#Library .toc li.focused {
  background: #00f;
}

#Library .toc li.focused a:link[href] {
  color: #fff;
}

#Library .toc li.focused a:visited[href] {
  color: #fffc;
}

#Video-Channel, #Trailers {
  background-color: #130036;
}

#Video-Channel .trailer-thumb, #Trailers .trailer-thumb {
  max-width: calc(50% - .5px);
  min-width: var(--column);
  display: inline-block;
}

#Video-Channel .deferredVimeoVideo iframe, #Trailers .deferredVimeoVideo iframe {
  width: 100vw;
  height: 56.25vw;
  z-index: 200;
  cursor: zoom-in;
  background: #000000b3;
  position: absolute;
  top: 0;
  left: 0;
}

#Video-Channel .deferredVimeoVideo summary, #Trailers .deferredVimeoVideo summary {
  list-style: none;
}

#Video-Channel .deferredVimeoVideo .placeholder, #Trailers .deferredVimeoVideo .placeholder {
  height: 0;
  overflow: hidden;
}

#Video-Channel details.deferredVimeoVideo[open] summary, #Trailers details.deferredVimeoVideo[open] summary {
  cursor: zoom-out;
  width: 200vw;
  height: 200vh;
  z-index: 100;
  cursor: zoom-out;
  background: #000000b3;
  position: absolute;
  top: -50vh;
  left: -50vw;
}

#Video-Channel details.deferredVimeoVideo[open] summary img, #Trailers details.deferredVimeoVideo[open] summary img {
  opacity: 0;
}

#Video-Channel details.deferredVimeoVideo[open] iframe, #Trailers details.deferredVimeoVideo[open] iframe {
  animation: fadeIn 1s ease-out;
}

#Video-Channel details.deferredVimeoVideo[open] .placeholder, #Trailers details.deferredVimeoVideo[open] .placeholder {
  height: auto;
}

#Archive > .body {
  height: var(--here-height);
  overflow: visible;
}

#Archive body, #Archive #root, #Archive #root > div {
  background: none;
  border: none;
}

.role + a.person {
  margin-left: .5rem;
  transition: all .2s;
}

.role + a.person:hover, a.medium:hover {
  letter-spacing: .5px;
}

ul.stretch-horizontally {
  width: 100%;
  display: flex;
}

ul.stretch-horizontally > li {
  flex-grow: 1;
}

ul.video-carousel {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.video-carousel li {
  width: 33.3%;
  margin: 0;
  padding: 0;
  display: inline-block;
}

ul.video-carousel.dense {
  max-width: 100%;
  display: flex;
  overflow-x: scroll;
}

ul.video-carousel.dense .seeLab, ul.video-carousel.dense .seeNothing {
  padding: .5rem;
  line-height: 1rem;
  display: block;
}

ul.video-carousel.dense summary {
  cursor: zoom-in;
  min-width: 4.5rem;
}

.traces-container {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

iframe.traces {
  width: 100%;
  height: 100%;
  border: none;
  position: absolute;
  left: 0;
}

.motto {
  font-family: space_monoregular;
  font-weight: bold;
  position: absolute;
  right: .25rem;
}

.artist .weblink {
  width: fit-content;
  border-bottom: .5px solid #8c8cfc;
  margin-top: 1.5rem;
  margin-left: -7rem;
  padding-left: 7rem;
  display: block;
}

.artist fieldset {
  background-image: linear-gradient(#0000, #00f, #0000);
  background-size: 400px 1000px;
  border: none;
  margin: 1.5rem -1rem;
  padding: .25rem 1rem 1rem;
  animation: scroll 7s linear infinite;
}

.artist fieldset .weblink {
  margin-top: 0;
}

.artist fieldset a:hover {
  background: #00f;
}

.artist a.internal[href] {
  background-image: linear-gradient(#0000, #00f, #0000);
  background-size: 400px 1000px;
  animation: scroll 7s linear infinite;
  display: block;
}

.artist a.internal[href]:hover {
  background: #00f;
}

.F > img.artist, .A img.artist {
  mix-blend-mode: overlay;
  width: calc(100% - var(--bleed) );
  position: relative;
  left: 1rem;
}

.generic.lab {
  padding: 0 1.125rem;
}

ul.credits {
  padding-left: 1.25rem;
}

.role + .who {
  margin-left: .25rem;
}

/*# sourceMappingURL=index.7498b26b.css.map */
